@import "../../App.scss";
.card-deck {
  margin-top: 25px;
  justify-content: space-evenly;
  .card {
    max-width: 320px;
    margin: 1.5em 39px;
    min-width: 26%;
    .card-img-top {
      max-width: 320px;
    }
    .title {
      font-size: 14px;
      color: $title-color;
      letter-spacing: 1px;
      text-align: left;
    }
    .sub-title {
      margin-top: 0.5em;
    }
    .tools {
      font-size: 12px;
      color: $sub-title-color;
      letter-spacing: 0.5px;
      text-align: left;
    }
    &:nth-child(2) .card-img-top {
      background: #0e0e0e;
      padding: 0 0 55px 0;
      margin: 0;
    }
    &:nth-child(3) .card-img-top {
      background: #0e0e0e;
      padding: 0 0 55px 0;
      margin: 0;
    }
    &:nth-child(4) .card-img-top {
      background: #0e0e0e;
      padding: 0 0 55px 0;
      margin: 0;
    }
  }
}

//Modal and Carousel
.modal-dialog {
  max-width: 100%;
}

.carousel-image {
  width: 60%;
  display: flex;
  margin: auto;
}

.carousel-control-next-icon {
  background-image: url(https://payam.ca/img/next.svg);
}

.carousel-control-prev-icon {
  background-image: url(https://payam.ca/img/prev.svg);
}

.carousel-control-next,
.carousel-control-prev {
  width: 5%;
}

.modal-dialog {
  margin: 0;
}

.none {
  display: none;
}
