@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap');
//Colors
$dark-gery:#2D2D2D;
$light-grey:#767676;
$yellow:#ff9800;
$title-color:$dark-gery;
$sub-title-color: $light-grey;
$dark-btn:$dark-gery;
$light-btn: $light-grey;
$underline: $yellow;
$break-small: 420px;
$break-medium: 740px;
* {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

.float-r {
    margin: 0 0 0 auto !important;
}

.tag {
    font-size: 0.7em;
}

.container {
    max-width: 1200px;
}

.page {
    min-height: 735px;
    height: 100%;
}