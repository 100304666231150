@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";
@import '../../App.scss';
.photos {
    margin-top: 1.5em;
    img {
        width: 90%;
    }
    .carousel-control-prev-icon {
        position: relative;
        left: -50px;
    }
    .carousel-control-next-icon {
        position: relative;
        right: -50px;
    }
}

//// react-image-gallery
.image-gallery {
    margin: 0 auto;
}

.image-gallery-slide-wrapper,
.image-gallery-thumbnails-wrapper {
    @media screen and (max-width: $break-medium) {
        width: 100% !important;
    }
}

.image-gallery-slide img {
    width: 100%;
    margin: auto;
    display: flex;
}

.image-gallery-thumbnails-wrapper {
    position: relative;
    width: 65%;
    display: flex;
    margin: auto;
}

.fullscreen .image-gallery-slide-wrapper,
.fullscreen .image-gallery-thumbnails-wrapper {
    width: 100%;
}

.image-gallery-slide-wrapper {
    position: relative;
    width: 65%;
    display: flex;
    margin: auto;
}

.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
    text-shadow: none;
}