@import '../../App.scss';
.more {
    background: #767676;
    border-radius: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
}

.big-modal {
    .modal-body {
        min-height: 800px;
    }
}

.carousel-big {
    margin: 1.5em auto;
    max-height: 580px;
    overflow: hidden;
}

.modal-title {
    font-size: 1.3em !important;
}

.modal-content {
    width: 70%;
    margin: auto;
    @media screen and (max-width: $break-medium) {
        width: 90% !important;
    }
    .detail-links {
        display: flex;
        flex-direction: row;
        a {
            color: $light-grey;
            margin-right: 15px;
            text-decoration: underline;
        }
    }
}

.close span {
    font-size: 1.6em;
    font-weight: lighter;
}