        @import '../../App.scss';
        .about {
            margin: 3em auto;
            @media screen and (max-width: $break-small) {
                margin: 1em auto;
            }
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .container {
                display: flex;
                flex-direction: row;
                @media screen and (max-width: $break-small) {
                    flex-direction: column-reverse;
                }
                height: 100%;
                .context {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    @media screen and (max-width: $break-medium) {
                        width: 60%;
                    }
                    @media screen and (max-width: $break-small) {
                        width: 100%;
                    }
                }
                p {
                    width: 93%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    margin: 0 1em;
                }
                .skills {
                    display: flex;
                    margin: 1em 0;
                    flex-direction: column;
                    .title {
                        margin: 1em 0;
                    }
                    ul {
                        list-style: circle;
                        padding: 0 15px;
                    }
                }
                .img {
                    width: 100%;
                    @media screen and (max-width: $break-medium) {
                        width: 50%;
                    }
                    @media screen and (max-width: $break-small) {
                        height: auto;
                        width: 66%;
                        border-radius: 50%;
                        margin: 1em auto;
                        display: block;
                    }
                    .about-img {
                        height: auto;
                        width: 100%;
                        border: none;
                        @media screen and (max-width: $break-small) {
                            border-radius: 50%;
                            -webkit-border-radius: 50%;
                            -moz-border-radius: 50%;
                            -ms-border-radius: 50%;
                            -o-border-radius: 50%;
                        }
                    }
                }
                .name {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
                    background-position: 0 100%;
                    background-size: auto 2px;
                    background-repeat: repeat-x;
                    text-decoration: none;
                    padding: 0 5px 0 0;
                }
            }
        }