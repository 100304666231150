@import '../../App.scss';
.footer {
    display: flex;
    flex-direction: column;
    margin-top: 4em;
    padding: 25px 0;
    background-color: $dark-gery;
    text-align: center;
    .container {
        display: flex;
        p {
            margin: auto;
            color: #fff;
            font-size: 0.8em;
            flex-basis: 50%;
            text-align: right;
        }
        .social-links {
            display: flex;
            flex-basis: 50%;
            a {
                color: #fff;
                display: flex;
                margin: auto 1.2em;
                font-size: 1.3em;
                text-decoration: none;
            }
        }
    }
}