.content {
    width: 100%;
    margin: 2em auto;
    padding: 0 15px;
    h4 {
        margin: 2em 0;
        span {
            color: #fd7e14;
        }
    }
    .accordion {
        .card {
            border: none;
            max-width: 1200px;
            margin: auto;
            color: #000;
            .card-header {
                background: transparent;
                padding: 0;
                .btn {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .card-body {
                background: #fff;
            }
        }
    }
}

.post {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 3em;
    img {
        width: 100%;
    }
    .sub {
        font-size: 0.8em;
    }
}