.modal-body img {
    display: flex;
    margin: auto;
    width: 100%;
}

video {
    width: 90%;
    margin: auto;
    display: flex;
}