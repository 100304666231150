@import '../../App.scss';
.navbar {
    margin-bottom: 2em;
    .container {
        padding: 0;
    }
    .logo {
        text-decoration: none;
    }
    padding-left: 20px;
    .title {
        font-size: 22px;
        color: $title-color;
        letter-spacing: 3.06px;
        text-align: left;
        font-family: 'Roboto', sans-serif;
    }
    .sub-title {
        font-size: 14px;
        color: $sub-title-color;
        letter-spacing: 0.5px;
        text-align: left;
    }
    .nav-items {
        a {
            background: linear-gradient(to bottom, var($underline) 0%, var($underline) 100%);
            background-position: 0 100%;
            background-repeat: repeat-x;
            background-size: 3px 3px;
            color: #000;
            text-decoration: none;
        }
        a:hover {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
            @media screen and (max-width: $break-medium) {
                background-image: none;
                cursor: pointer;
            }
            background-position: 0 100%;
            background-size: auto 6px;
            background-repeat: repeat-x;
            text-decoration: none;
        }
    }
}

.nav-items {
    .nav-link {
        font-size: 14px;
        color: $title-color !important;
        letter-spacing: 1px;
        text-align: left;
        margin: 0 0 0 25px;
        @media screen and (max-width: $break-medium) {
            text-align: center !important;
            margin: 0;
        }
    }
    //About page option 2 - dropdown
    .about-dropdown {
        .dropdown-menu {
            left: -5em;
        }
        .social-links {
            display: flex;
            text-align: center;
        }
    }
}